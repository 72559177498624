/* :root {
  --fc-event-border-color: rgb(122, 220, 180) !important;
  --fc-event-bg-color: rgb(122, 220, 180) !important;
} */

.fc .btn-primary {
  background-color: rgb(73, 97, 117) !important;
  border-color: rgb(73, 97, 117, 0.8) !important;
}

/* Ajustes para botones de navegación y cualquier otro botón primario */
.fc .fc-button-primary {
  background-color: rgb(73, 97, 117);
  border-color: rgb(73, 97, 117, 0.8) !important;
}

.fc-button-active,
.fc .fc-button-primary:active {
  background-color: rgba(73, 97, 117, 0.8) !important;
  box-shadow: none !important;
}

.fc-list-event-time {
  display: none;
}

.fc-dayGridMonth-button {
  padding: 0 25px !important;
}

.fc-event {
  cursor: pointer;
}

.fc .fc-button {
  font-size: 16px;
  padding: 2px 6px;
  min-width: auto;
}

.fc-event-main {
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fc-daygrid-day-top {
  font-size: 14px;
}

.fc-day-sat,
.fc-day-sun {
  background-color: #f0f0f07a; /* Color de fondo opcional para destacar */
  width: 12% !important; /* Ajusta el ancho según tus necesidades */
}

.event-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: calc(100% - 24px); /* Ajusta este valor según sea necesario */
  vertical-align: middle;
}

.custom-event {
  font-size: 12px;
}
